import { useEffect, useState } from 'react';
// import { Route, Switch, useHistory } from 'react-router-dom';

import AppRouter from 'AppRouter';
import AuthRouter from 'AuthRouter';
import Unsupported from 'pages/unsupported/Unsupported';
import AuthLayout from 'templates/layouts/AuthLayout';
import MainLayout from 'templates/layouts/MainLayout';

import ResetPassword from 'pages/auth/ResetPassword';
import ResetPasswordConfirm from 'pages/auth/ResetPasswordConfirmation';

import ReactGA from 'react-ga4';

// import { initializeFaro } from '@grafana/faro-web-sdk';
import { Route, Switch, useHistory } from 'react-router-dom';

// import { withFaroErrorBoundary } from '@grafana/faro-react';
import { withFaroProfiler } from '@grafana/faro-react';

function App(props: any) {
  const history = useHistory();
  const [isUnsupported, setIsUnsupported] = useState(false);

  const checkScreenSize = () => {
    const screenSize = window.innerWidth;

    if (screenSize <= 1024) {
      setIsUnsupported(true);
    } else {
      setIsUnsupported(false);
    }
  };

  useEffect(() => {
    ReactGA.initialize([{ trackingId: 'G-ERW14P60X8' }]);
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }, []);

  // useEffect(() => {
  //   initializeFaro({
  //     url: 'http://grafana-agent.academy.mog.local/collect',
  //     app: {
  //       name: 'mfh-map',
  //       version: '1.0.0',
  //     },
  //     instrumentations: [
  //       // Load the default Web instrumentations
  //       ...getWebInstrumentations(),

  //       // Tracing Instrumentation is needed if you want to use the React Profiler
  //       new TracingInstrumentation(),

  //       new ReactIntegration({
  //         // Or if you use react-router v4/v5
  //         router: {
  //           version: ReactRouterVersion.V5, // or ReactRouterVersion.V4,
  //           dependencies: {
  //             history, // the history object used by react-router
  //             Route, // Route component imported from react-router package
  //           },
  //         },
  //       }),
  //     ],
  //   });

  //   checkScreenSize();
  //   window.addEventListener('resize', checkScreenSize);

  //   return () => {
  //     window.removeEventListener('resize', checkScreenSize);
  //   };
  // }, []);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  if (isUnsupported) {
    return <Unsupported {...props} />;
  }
  return (
    <Switch>
      {/* <Route path='/auth/resetPassword' exact>
        <AuthRouter history={history} />
      </Route>
      <Route path='/auth/resetPasswordConfirmation/:id' exact>
        <AuthRouter history={history} />
      </Route> */}
      <Route path='/auth/resetPassword' component={ResetPassword} />
      <Route
        path='/auth/resetPasswordConfirmation/:id'
        component={ResetPasswordConfirm}
      />
      <Route path='/auth'>
        <AuthLayout history={history}>
          <AuthRouter history={history} />
        </AuthLayout>
      </Route>
      <Route path='/'>
        <MainLayout history={history}>
          <AppRouter />
        </MainLayout>
      </Route>
    </Switch>
  );
}

export default withFaroProfiler(App);
